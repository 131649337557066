/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import useContentfulNews from '../hooks/use-contentfulNews';
import useContentfulSmallNews from '../hooks/use-contentfulSmallNews';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export default () => {
  const breakpoints = useBreakpoint();
  const contentfulNews = useContentfulNews();
  const contentfulSmallNews = useContentfulSmallNews();

  const [isIOS, setIsIOS] = useState(true);
  const [isSafari, setIsSafari] = useState(true);

  useEffect(() => {
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;

    if (isSafari === true) {
      setIsSafari(true);
    } else {
      setIsSafari(false);
    }

    function iOS() {
      return (
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      );
    }
    let appleTest = iOS();
    // let appleTest =
    //   /iPad|iPhone|iPod/.test(navigator.platform) ||
    //   (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (appleTest === true) {
      setIsIOS(true);
    } else {
      setIsIOS(false);
    }
  }, []);

  return isIOS || isSafari ? (
    <>
      <div
        style={{
          color: '#1f2833',
          fontSize: '3rem',
          paddingTop: '10vh',
          textAlign: 'center',
        }}
      >
        News
      </div>
      <div className="newsWrapper">
        {contentfulSmallNews.map((post) => (
          <article className="postPreview" key={post.slug}>
            <Link
              to={`/${post.slug}`}
              className="linkToImage"
              title={`/${post.slug}`}
              aria-label={`/${post.slug}`}
            >
              <Image
                className="postPreview__Image"
                alt={post.imageMultiple[0].fixed.description}
                fixed={post.imageMultiple[0].fixed}
                key={post.id}
              />
            </Link>
            <h3 className="postPreviewTitle">
              <Link
                to={`/${post.slug}`}
                style={{
                  textDecoration: 'none',
                  fontSize: '2rem',
                  color: ' $color-3',
                }}
              >
                {post.title}
              </Link>
            </h3>
          </article>
        ))}
      </div>
    </>
  ) : (
    <>
      <div
        style={{
          color: '#1f2833',
          fontSize: '3rem',
          paddingTop: '5vh',
          textAlign: 'center',
        }}
      >
        News
      </div>
      <div className="newsWrapper">
        {breakpoints.xs
          ? contentfulSmallNews.map((post) => (
              <article className="postPreview" key={post.slug}>
                <Link
                  to={`/${post.slug}`}
                  className="linkToImage"
                  title={`/${post.slug}`}
                  aria-label={`/${post.slug}`}
                >
                  <Image
                    className="postPreview__Image"
                    alt={post.imageMultiple[0].fixed.description}
                    fixed={post.imageMultiple[0].fixed}
                    key={post.id}
                  />
                </Link>
                <h3 className="postPreviewTitle">
                  <Link
                    to={`/${post.slug}`}
                    style={{
                      textDecoration: 'none',
                      fontSize: '2rem',
                      color: ' $color-3',
                    }}
                  >
                    {post.title}
                  </Link>
                </h3>
              </article>
            ))
          : contentfulNews.map((post) => (
              <article className="postPreview" key={post.slug}>
                <Link
                  to={`/${post.slug}`}
                  className="linkToImage"
                  title={`/${post.slug}`}
                  aria-label={`/${post.slug}`}
                >
                  <Image
                    className="postPreview__Image"
                    alt={post.imageMultiple[0].fixed.description}
                    fixed={post.imageMultiple[0].fixed}
                    key={post.id}
                  />
                </Link>
                <h3 className="postPreviewTitle">
                  <Link
                    to={`/${post.slug}`}
                    style={{
                      textDecoration: 'none',
                      fontSize: '2rem',
                      color: ' $color-3',
                    }}
                  >
                    {post.title}
                  </Link>
                </h3>
              </article>
            ))}
      </div>
    </>
  );
};
